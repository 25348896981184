import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './Skeleton.styles';
interface Props extends PropsWithClassName {
  width?: string;
  height?: string;
  children?: React.ReactNode;
  round?: boolean;
  as?: string;
}
const Skeleton = (props: Props): React.ReactElement => {
  const {
    dataTestId,
    children,
    width,
    height,
    round,
    as
  } = props;
  return <Root width={width ?? 'auto'} height={height ?? 'auto'} className={props.className} round={round ?? false} as={as} data-testid={dataTestId ? dataTestId : 'Skeleton-root'} data-sentry-element="Root" data-sentry-component="Skeleton" data-sentry-source-file="Skeleton.tsx">
      {children}
    </Root>;
};
export { Skeleton };