import { SingleOption } from '@Components/atoms/Form/InputSelect/InputSelect';

export const DEFAULT_LANGUAGE_ISO = 'en';
export const DEFAULT_LANGUAGE_NAME = 'english';

export const englishSingleOptionFallback: SingleOption = {
  value: DEFAULT_LANGUAGE_ISO,
  label: 'English (english)',
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
