import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import { TextColor, TextSize } from '@Helpers/types/text';
import { Root } from './ReadingTime.styles';
interface Props extends PropsWithClassName {
  readingTime: number | undefined;
}
const ReadingTime: React.FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>): React.ReactElement => {
  const readTime = props.readingTime;
  const {
    t
  } = useTranslation('blog');
  return <Root className={props.className} data-testid={'reading-time-root'} data-sentry-element="Root" data-sentry-component="ReadingTime" data-sentry-source-file="ReadingTime.tsx">
      {readTime !== undefined && readTime > 0 && <Text size={TextSize.Small} color={TextColor.Tertiary}>
          {t('reading_time', {
        min: readTime
      })}
        </Text>}
    </Root>;
};
export default ReadingTime;