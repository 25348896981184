import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '@Components/atoms/Skeleton';
import { PropsWithClassName } from '@Components/helper';
import { DateTimeFormats, formatDate, getDateInTimezone } from '@Components/helper/date';
import { englishSingleOptionFallback } from '@Components/helper/language';
import { getStringWithLength } from '@Components/helper/utils';
import { Root } from './DateTime.styles';
interface Props extends PropsWithClassName {
  date: string;
  dateFormat?: DateTimeFormats;
}
const DateTime = (props: Props): React.ReactElement => {
  const {
    date,
    dateFormat
  } = props;
  const {
    i18n
  } = useTranslation();
  const locale = i18n?.language ?? englishSingleOptionFallback.value;
  const [formattedDate, setFormattedDate] = useState<null | string>(null);
  useEffect(() => {
    const fetchFormattedDate = async () => {
      const result = await formatDate(date, locale, dateFormat);
      setFormattedDate(result);
    };
    fetchFormattedDate().catch(reason => new Error(reason));
  }, [date, dateFormat, locale]);
  return <Root className={props.className} dateTime={getDateInTimezone(date).toISOString()} data-testid={'DateTime-root'} data-sentry-element="Root" data-sentry-component="DateTime" data-sentry-source-file="DateTime.tsx">
      {formattedDate ?? <Skeleton as="span"> {getStringWithLength(15)}</Skeleton>}
    </Root>;
};
export { DateTime };