export type LocalazyLanguage = {
    language: string;
    region: string;
    script: string;
    isRtl: boolean;
    localizedName: string;
    name: string;
    aliasOf?: string | null | undefined;
    expansionOf?: string | null | undefined;
    pluralType: (n: number) => "zero" | "one" | "two" | "many" | "few" | "other";
};
export type LocalazyFile = {
    cdnHash: string;
    file: string;
    path: string;
    library?: string | null | undefined;
    module?: string | null | undefined;
    buildType?: string | null | undefined;
    productFlavors?: string[] | null | undefined;
    cdnFiles: { [lang:string]: string };
};
export type LocalazyMetadata = {
    projectUrl: string;
    baseLocale: string;
    languages: LocalazyLanguage[];
};
             
const localazyMetadata: LocalazyMetadata = {
  projectUrl: "https://localazy.com/p/10fastfingers",
  baseLocale: "en",
  languages: [
    {
      language: "ar",
      region: "",
      script: "",
      isRtl: true,
      name: "Arabic",
      localizedName: "العربية",
      pluralType: (n) => { return (n===0) ? "zero" : (n===1) ? "one" : (n===2) ? "two" : (n%100>=3 && n%100<=10) ? "few" : (n%100>=11 && n%100<=99) ? "many" : "other"; }
    },
    {
      language: "az",
      region: "",
      script: "",
      isRtl: false,
      name: "Azerbaijani",
      localizedName: "Azərbaycan",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "bn",
      region: "",
      script: "",
      isRtl: false,
      name: "Bangla",
      localizedName: "বাংলা",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "cs",
      region: "",
      script: "",
      isRtl: false,
      name: "Czech",
      localizedName: "Čeština",
      pluralType: (n) => { return (n===1) ? "one" : (n>=2 && n<=4) ? "few" : "other"; }
    },
    {
      language: "da",
      region: "",
      script: "",
      isRtl: false,
      name: "Danish",
      localizedName: "Dansk",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "de",
      region: "",
      script: "",
      isRtl: false,
      name: "German",
      localizedName: "Deutsch",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "es",
      region: "",
      script: "",
      isRtl: false,
      name: "Spanish",
      localizedName: "Español",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "et",
      region: "",
      script: "",
      isRtl: false,
      name: "Estonian",
      localizedName: "Eesti",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fa",
      region: "",
      script: "",
      isRtl: true,
      name: "Persian",
      localizedName: "فارسی",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "fi",
      region: "",
      script: "",
      isRtl: false,
      name: "Finnish",
      localizedName: "Suomi",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "fr",
      region: "",
      script: "",
      isRtl: false,
      name: "French",
      localizedName: "Français",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "gl",
      region: "",
      script: "",
      isRtl: false,
      name: "Galician",
      localizedName: "Galego",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "gu",
      region: "",
      script: "",
      isRtl: false,
      name: "Gujarati",
      localizedName: "ગુજરાતી",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "he",
      region: "",
      script: "",
      isRtl: true,
      name: "Hebrew",
      localizedName: "עברית",
      pluralType: (n) => { return (n===1) ? "one" : (n===2) ? "two" : (((n<0 || n>10)) && (n%10===0)) ? "many" : "other"; }
    },
    {
      language: "hi",
      region: "",
      script: "",
      isRtl: false,
      name: "Hindi",
      localizedName: "हिन्दी",
      pluralType: (n) => { return (n===0 || n===1) ? "one" : "other"; }
    },
    {
      language: "hr",
      region: "",
      script: "",
      isRtl: false,
      name: "Croatian",
      localizedName: "Hrvatski",
      pluralType: (n) => { return ((n%10===1) && (n%100!==11)) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "other"; }
    },
    {
      language: "hu",
      region: "",
      script: "",
      isRtl: false,
      name: "Hungarian",
      localizedName: "Magyar",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "id",
      region: "",
      script: "",
      isRtl: false,
      name: "Indonesian",
      localizedName: "Indonesia",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "is",
      region: "",
      script: "",
      isRtl: false,
      name: "Icelandic",
      localizedName: "Íslenska",
      pluralType: (n) => { return ((n%10===1) && (n%100!==11)) ? "one" : "other"; }
    },
    {
      language: "it",
      region: "",
      script: "",
      isRtl: false,
      name: "Italian",
      localizedName: "Italiano",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "ja",
      region: "",
      script: "",
      isRtl: false,
      name: "Japanese",
      localizedName: "日本語",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "km",
      region: "",
      script: "",
      isRtl: false,
      name: "Khmer",
      localizedName: "ខ្មែរ",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "ko",
      region: "",
      script: "",
      isRtl: false,
      name: "Korean",
      localizedName: "한국어",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "lt",
      region: "",
      script: "",
      isRtl: false,
      name: "Lithuanian",
      localizedName: "Lietuvių",
      pluralType: (n) => { return ((n%10===1) && ((n%100<11 || n%100>19))) ? "one" : ((n%10>=2 && n%10<=9) && ((n%100<11 || n%100>19))) ? "few" : "other"; }
    },
    {
      language: "mr",
      region: "",
      script: "",
      isRtl: false,
      name: "Marathi",
      localizedName: "मराठी",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "ms",
      region: "",
      script: "",
      isRtl: false,
      name: "Malay",
      localizedName: "Melayu",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "nb",
      region: "",
      script: "",
      isRtl: false,
      name: "Norwegian Bokmål",
      localizedName: "Norsk Bokmål",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "nl",
      region: "",
      script: "",
      isRtl: false,
      name: "Dutch",
      localizedName: "Nederlands",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "pa",
      region: "",
      script: "",
      isRtl: false,
      name: "Punjabi",
      localizedName: "ਪੰਜਾਬੀ",
      pluralType: (n) => { return (n>=0 && n<=1) ? "one" : "other"; }
    },
    {
      language: "pl",
      region: "",
      script: "",
      isRtl: false,
      name: "Polish",
      localizedName: "Polski",
      pluralType: (n) => { return (n===1) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "many"; }
    },
    {
      language: "pt",
      region: "",
      script: "",
      isRtl: false,
      name: "Portuguese",
      localizedName: "Português",
      pluralType: (n) => { return (n>=0 && n<=1) ? "one" : "other"; }
    },
    {
      language: "ro",
      region: "",
      script: "",
      isRtl: false,
      name: "Romanian",
      localizedName: "Română",
      pluralType: (n) => { return (n===1) ? "one" : (n===0 || n%100>=2 && n%100<=19) ? "few" : "other"; }
    },
    {
      language: "ru",
      region: "",
      script: "",
      isRtl: false,
      name: "Russian",
      localizedName: "Русский",
      pluralType: (n) => { return ((n%10===1) && (n%100!==11)) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "many"; }
    },
    {
      language: "sk",
      region: "",
      script: "",
      isRtl: false,
      name: "Slovak",
      localizedName: "Slovenčina",
      pluralType: (n) => { return (n===1) ? "one" : (n>=2 && n<=4) ? "few" : "other"; }
    },
    {
      language: "sv",
      region: "",
      script: "",
      isRtl: false,
      name: "Swedish",
      localizedName: "Svenska",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "sw",
      region: "",
      script: "",
      isRtl: false,
      name: "Swahili",
      localizedName: "Kiswahili",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "ta",
      region: "",
      script: "",
      isRtl: false,
      name: "Tamil",
      localizedName: "தமிழ்",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "th",
      region: "",
      script: "",
      isRtl: false,
      name: "Thai",
      localizedName: "ไทย",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "tr",
      region: "",
      script: "",
      isRtl: false,
      name: "Turkish",
      localizedName: "Türkçe",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "uk",
      region: "",
      script: "",
      isRtl: false,
      name: "Ukrainian",
      localizedName: "Українська",
      pluralType: (n) => { return ((n%10===1) && (n%100!==11)) ? "one" : ((n%10>=2 && n%10<=4) && ((n%100<12 || n%100>14))) ? "few" : "many"; }
    },
    {
      language: "vi",
      region: "",
      script: "",
      isRtl: false,
      name: "Vietnamese",
      localizedName: "Tiếng Việt",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "zh",
      region: "CN",
      script: "Hans",
      isRtl: false,
      name: "Chinese (China, Simplified)",
      localizedName: "中文 (中国, 简体)",
      pluralType: (n) => { return "other"; }
    },
    {
      language: "zh",
      region: "CN",
      script: "Hant",
      isRtl: false,
      name: "Chinese (China, Traditional)",
      localizedName: "中文 (中國, 繁體)",
      pluralType: (n) => { return "other"; }
    }
  ],
};

export default localazyMetadata;