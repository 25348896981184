'use client';

import { createInstance, Resource } from 'i18next';
import { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import initClientTranslations from '@Root/src/helpers/i18n/client';
export default function TranslationsProvider({
  children,
  locale,
  namespaces,
  resources
}: {
  children: ReactNode;
  locale: string;
  namespaces: string[];
  resources: Resource;
}) {
  const i18n = createInstance();
  initClientTranslations(locale, namespaces, i18n, resources);
  return <I18nextProvider i18n={i18n} data-sentry-element="I18nextProvider" data-sentry-component="TranslationsProvider" data-sentry-source-file="TranslationsProvider.tsx">{children}</I18nextProvider>;
}